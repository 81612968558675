<template>
  <div class="bbr-user-membership--pause d-flex align-center">
    <v-row class="d-flex align-center">
      <v-col cols="12" md="7">
        <h4>Pause Membership</h4>
        <div>
          <p v-if="isPaused">
            <span>
              This membership was paused on
            </span>
            <span class="font-weight-bold">
              {{ this.status.start_date | formatDate('MMMM D, YYYY HH:mm ') }}
            </span>
          </p>
          <p v-if="isPaused">
            <span>The pause will end automatically on</span>
            <span class="font-weight-bold">
              {{ this.status.end_date | formatDate('MMMM D, YYYY HH:mm ') }}
            </span>
          </p>
        </div>
      </v-col>

      <v-col cols="12" md="5" class="text-right">
        <div v-if="!isPaused">
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="220px"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="form.end_date"
                :append-icon="icons.calendar"
                label="Pause until"
                clearable
                readonly
                outlined
                flat
                :error-messages="form.$getError('end_date')"
              />
            </template>
            <v-date-picker
              min="2020-01-01"
              v-model="form.end_date"
              @input="dateMenu1 = false"
            />
          </v-menu>
          <v-text-field
            v-on="on"
            v-model="form.justification"
            label="Justification"
            clearable
            outlined
            flat
            :error-messages="form.$getError('justification')"
          />
        </div>

        <v-btn
          v-if="!isPaused"
          class="px-10"
          color="primary"
          :loading="loading"
          :disabled="isPaused"
          @click="deactivate"
        >
          Pause Membership
        </v-btn>

        <div v-else>
          <v-btn
            class="px-10"
            color="primary"
            :loading="loading"
            @click="reactivate"
          >
            Unpause Membership
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDeactivation" @close="confirmed" />
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapState } from 'vuex'
import Form from '@/utils/form'
import moment from 'moment-timezone'

export default {
  name: 'PauseUserMembership',

  components: {
    ConfirmDialog,
  },

  props: {
    userId: {
      type: [Number, String],
      required: true,
    },

    membershipId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,

      icons: {
        calendar: mdiCalendar,
      },

      dateConfig: {
        type: 'string',
        mask: 'iso',
      },

      tzFormat: (date) => {
        return date ? moment(date).format('lll') : null
      },

      dateMenu1: false,

      form: new Form({
        justification: null,
        end_date: null,
      }),
    }
  },

  computed: {
    ...mapState({
      membership: (state) => state.userProfile.selectedMembership,
    }),

    status() {
      return this.membership &&
        this.membership.user_subscription.pause_status.length > 0
        ? this.membership.user_subscription.pause_status[0]
        : null
    },

    isPaused() {
      return this.status ? this.status.active : false
    },

    pausedAt() {
      return this.status ? this.status.start_date : false
    },

    isDefaultProgram() {
      return this.membership
        ? this.membership.user_subscription.is_default
        : false
    },
  },

  methods: {
    ...mapActions({
      pauseUserMembership: 'userProfile/pauseUserMembership',
      unpauseUserMembership: 'userProfile/unpauseUserMembership',
    }),

    confirmed(confirmed) {
      return confirmed
    },

    async deactivate() {
      const confirm = await this.$refs.confirmDeactivation.open(
        'Pause Membership',
        'Are you sure you want to pause this membership?'
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.pauseUserMembership({
          userId: this.$route.params.id,
          userSubscriptionId: this.membershipId,
          start_date: moment(new Date())
            .tz('UTC')
            .format('YYYY-MM-DD HH:mm'),
          end_date: moment(this.form.end_date).format('YYYY-MM-DD HH:mm'),
          justification: this.form.justification,
        })

        setTimeout(() => {
          this.loading = false
          this.$emit('deactivated', true)
        }, 900)
      } catch (err) {
        this.loading = false

        this.form.$timeout(() => {
          if (err.response && err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }

          this.form.$busy = false
        })
      }
    },

    async reactivate() {
      const confirm = await this.$refs.confirmDeactivation.open(
        'Unpause Membership',
        'Are you sure you want to unpause this membership?'
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.unpauseUserMembership({
          userId: this.$route.params.id,
          pauseDetails: {
            id: this.membership.user_subscription.pause_status[0].id,
            user_subscription_id: this.membershipId,
            start_date: moment(
              this.membership.user_subscription.pause_status[0].start_date
            )
              .tz('UTC')
              .format('YYYY-MM-DD HH:mm'),
            end_date: moment(
              this.membership.user_subscription.pause_status[0].end_date
            )
              .tz('UTC')
              .format('YYYY-MM-DD HH:mm'),
            justification: this.membership.user_subscription.pause_status[0]
              .justification,
          },
        })

        setTimeout(() => {
          this.loading = false
          this.$emit('reactivated', true)
        }, 900)
      } catch (err) {
        this.loading = false

        this.form.$timeout(() => {
          if (err.response && err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }

          this.form.$busy = false
        })
      }
    },
  },
}
</script>
