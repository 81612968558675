<template>
  <div class="user-membership--details-form">
    <v-alert v-if="deactivatedAt" type="error" text prominent>
      <v-row align="center">
        <v-col class="grow">
          <span v-if="willBeDeactivated">
            This membership will be cancelled on
          </span>
          <span v-else>
            Membership has been cancelled last
          </span>

          <span class="font-weight-bold">
            {{ lastDate | formatDate('MMMM D, YYYY HH:mm a') }}.
          </span>
        </v-col>
      </v-row>
    </v-alert>
    <br />

    <!-- <change-user-membership-validity
      :validUntil="form.valid_until"
      :userId="$route.params.id"
      :membershipId="$route.params.userSubscriptionId"
    /> -->

    <deactivate-membership
      :userId="$route.params.id"
      :membershipId="$route.params.userSubscriptionId"
      @deactivated="goBack"
      @reactivated="goBack"
    />
    <pause-user-membership
      :userId="$route.params.id"
      :membershipId="$route.params.userSubscriptionId"
      @deactivated="goBack"
      @reactivated="goBack"
    />
  </div>
</template>

<script>
import DeactivateMembership from '@/components/elements/memberships/DeactivateUserMembership'
import PauseUserMembership from '@/components/elements/memberships/PauseUserMembership'
// import ChangeUserMembershipValidity from '@/components/elements/memberships/ChangeUserSubscriptionValidity'
import Form from '@/utils/form'
import moment from 'moment'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'MembershipDetailsForm',

  components: {
    DeactivateMembership,
    PauseUserMembership,
    // ChangeUserMembershipValidity
  },

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      form: this.formData,

      loading: false,
      startDateMenu: false,
    }
  },

  computed: {
    ...mapState({
      membership: (state) => state.userProfile.selectedMembership,
    }),

    isDeactivated() {
      return this.membership
        ? !!this.membership.user_subscription.cancelled_at
        : false
    },

    willBeDeactivated() {
      return (
        this.deactivatedAt &&
        moment().isBefore(this.membership.user_subscription.valid_until)
      )
    },

    deactivatedAt() {
      return this.membership
        ? this.membership.user_subscription.cancelled_at ||
            this.membership.user_subscription.deactivated_at
        : false
    },
    lastDate() {
      return this.membership
        ? this.membership.user_subscription.valid_until
        : false
    },

    status() {
      return this.membership ? this.membership.user_subscription.status : null
    },
  },

  created() {
    // this.fetchPlan()
  },

  methods: {
    ...mapActions({
      // getPlan: 'plans/getPlan'
    }),

    goBack() {
      this.$router.back(1)
    },
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },
}
</script>
