<template>
  <div class="user-profile--memberships-details mb-12 pb-12">
    <v-row>
      <v-col cols="12" md="12" lg="8" style="position: relative">
        <div v-if="loading" class="mx-3 py-14">
          <v-overlay
            class="mx-3 py-12 rounded-lg"
            :opacity="0.3"
            :value="loading"
            absolute
          >
            <v-progress-circular indeterminate size="64" />
          </v-overlay>
        </div>

        <div v-if="!loading" class="d-flex align-center">
          <h2 class="mr-auto">
            <span v-if="selectedMembership" class="primary--text">
              {{ selectedMembership.name }}
            </span>
            - Membership Details
          </h2>

          <v-btn
            class="bg-primary-gradient primary mr-2"
            depressed
            text
            v-if="!isDefault"
            :loading="busy"
            @click="setDefault(selectedMembership.user_subscription.id)"
          >
            <v-icon>{{ icons.checkBold }}</v-icon>
            Set as default
          </v-btn>
          <v-btn
            class="bg-primary-gradient primary back-button px-12"
            @click="$router.back(1)"
            depressed
            text
          >
            <v-icon> {{ icons.back }} </v-icon>
            Go Back
          </v-btn>
        </div>

        <div v-if="!loading" class="user-profile--memberships-details mt-8">
          <membership-details-form :form-data="form" />
        </div>
      </v-col>
      <v-toast ref="toast" />
    </v-row>
  </div>
</template>

<script>
import MembershipDetailsForm from '../components/MembershipDetailsForm'
import VToast from '@/components/elements/Toast'
import Form from '@/utils/form'
import dayjs from 'dayjs'
import { values, flattenDeep } from 'lodash'
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiArrowLeft, mdiCheckBold } from '@mdi/js'

export default {
  name: 'UserMembershipDetails',

  components: {
    MembershipDetailsForm,
    VToast,
  },

  data() {
    return {
      loading: true,
      overlay: true,

      icons: {
        back: mdiArrowLeft,
        checkBold: mdiCheckBold,
      },

      form: new Form({
        starts_at: '',
        valid_until: '',
        rounds: 0,
        membership_id: null,
      }),

      busy: false,
      isDefault: false,
    }
  },

  async created() {
    this.fetchMembership()
    const timer = setInterval(() => {
      if (this.selectedMembership) {
        this.isDefault = this.selectedMembership.user_subscription.is_default
        clearInterval(timer)
      }
    }, 300)
  },

  destroyed() {
    this.clearSelectedMembership()
    this.isDefault = false
  },

  computed: {
    ...mapState({
      selectedMembership: (state) => state.userProfile.selectedMembership,
    }),
  },

  watch: {
    selectedMembership: function(membership) {
      this.form.valid_until = dayjs
        .utc(membership.user_subscription.valid_until)
        .format('YYYY-MM-DD')

      this.form.membership_id = membership.id
    },
  },

  methods: {
    ...mapActions({
      getMembershipDetails: 'userProfile/getMembershipDetails',
      assignDefaultMembership: 'users/assignDefaultMembership',
    }),

    ...mapMutations({
      clearSelectedMembership: 'userProfile/clearSelectedMembership',
    }),

    async fetchMembership() {
      this.loading = true

      await this.getMembershipDetails(this.$route.params)

      await this.$sleep()

      this.loading = false
    },

    async setDefault(userSubscriptionId) {
      this.busy = true
      try {
        await this.assignDefaultMembership({
          userId: this.$route.params.id,
          userSubscriptionId,
        })

        this.$refs.toast.open({
          color: 'primary',
          message: 'Successfully assign new default membership.',
        })
        this.busy = false
        this.isDefault = true
      } catch (err) {
        this.busy = false
        if (err) {
          if (err.response.data !== undefined) {
            this.$refs.toast.open({
              color: 'red',
              timeout: 3000,
              message: flattenDeep(values(err.response.data.errors)).join(' '),
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
.breadcrumbs {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
